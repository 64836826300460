
(function(){
'use strict';

    /*  IMPORTANT: This needs to be first legacy wack app that loads after ng-core bundle */

// KOHA.status.configured.then(function(koha){
    // now we have KOHA.config, and all optional modules are included...


console.log(' declaring KOHAPAC angular module ... ');
console.log(KOHA.ngModules);
// prob need an await here.
angular.module('kohapac', KOHA.ngModules);

angular.module('kohapac').

  constant( 'VERSION', KOHA.VERSION).
  constant( 'DEBUG', true ).
  config(["$urlRouterProvider", "$stateProvider", "$httpProvider", "$locationProvider", "$provide", "$ocLazyLoadProvider", "$rootScopeProvider", "uibPaginationConfig", "uibDatepickerPopupConfig", "$uibModalProvider",
        function($urlRouterProvider, $stateProvider, $httpProvider, $locationProvider,
        $provide, $ocLazyLoadProvider, $rootScopeProvider, uibPaginationConfig, uibDatepickerPopupConfig, $uibModalProvider) {

    $stateProvider
        .state('test', {
            url: '/app/test',
            template: '<b>test</b>',
            controller: function() {
                console.log("Test");
            }
        })
        .state('home', {
            url: '/',
            templateUrl: '/app/static/partials/home.html',
            pageSubTitle: 'Home',
            controller: 'LandingPageCtrl',
        })

        .state('version', {
            url: '/app/version',
            templateUrl: '/app/static/partials/home.html',
            controller: 'VersionCtrl'
        })
        .state('error', {
            url: '/app/error?code',
            templateUrl: '/app/static/partials/syserror.html',
            controller: 'AppErrorCtrl'
        })
        .state('search', {
            url: '/app/search',
            templateUrl: '/app/static/partials/search.html',
            controller: 'SearchCtrl',
            data: {
                pageSubTitle: 'Search'
            },
            pageSubTitle: 'Search'
        })
        .state('search-results', {
            abstract: true,
            templateUrl: '/app/static/partials/results.html',
            controller: 'SearchResultsCtrl',
            // reloadOnSearch: true, // parent states are retained -- only the child state reloads.
            resolve: {
                resolvedUser: ["userService", function(userService) {
                    return userService.whenAnyUserDetails({redirectOnFail: '/'});
                }],
                searchQuery: ["SearchQuery", "$transition$", function(SearchQuery, $transition$) {
                    // fixme: searchquery obj is bv/solr-specific, doesn't really work for federated children
                    return new SearchQuery({ fromState: $transition$ });
                }]
            },
            onEnter: function(){
              console.log("enter search-results abstract state.");
            }
        });
        var searchQueryParams = Object.keys(KOHA.config.searchFields).join('&');
        $stateProvider
        .state('search-results.koha', {
            // url: '/app/search/{query:any}?QUERYPARAMS', // cf: https://ui-router.github.io/ng1/docs/latest/classes/params.paramtypes.html#any
            url: '/app/search/{query:any}?fq&page&sort&op&' + searchQueryParams,
            data: { pageSubTitle: 'Catalog Search Results' },
            resolve: {
                searchQuery: ["SearchQuery", "$transition$", function(SearchQuery, $transition$) {
                    // duplicates parent, but this reloads.
                    return new SearchQuery({ fromState: $transition$ });
                }],

            },
            views: {
                facets : {
                    templateUrl: '/app/static/partials/facets-cat.html',
                    controller: 'KohaFacetViewCtrl'
                },
                resultlist: {
                    templateUrl: '/app/static/partials/resultlist-cat.html',
                    controller: 'KohaSearchResultsCtrl'
                },
                searchsummary: {
                    templateUrl: '/app/static/partials/searchsummary-cat.html',
                    controller: 'KohaSearchSummaryCtrl',
                },
                querysummary: {
                    component: 'searchQuerySummary',
                    bindings: {
                        search : 'searchQuery',
                    }
                }
            },
            onEnter: function(){
              console.log("enter search-results.koha");
            }
        })
        .state('search-results.eds', {
            url: '/app/eds-search?query&query-1&publicationid&relatedcontent&highlight&pagenumber&resultsperpage&view&expander&searchmode&limiter&sort&facetfilter&includefacets',
            data: { pageSubTitle: 'Article Search Results' },
            reloadOnSearch: true,
            views: {
                facets : {
                    templateUrl: '/app/static/partials/facets-ebsco.html',
                    controller: 'EbscoFacetCtrl'
                },
                resultlist: {
                    templateUrl: '/app/static/partials/resultlist-ebsco.html',
                    controller: 'EbscoSearchResultsCtrl'
                },
                searchsummary: {
                    templateUrl: '/app/static/partials/searchsummary-ebsco.html',
                    controller: 'EbscoSearchSummaryCtrl'
                },
                querysummary: {
                    component: 'searchQuerySummary',
                    bindings: {
                        search : 'searchQuery',
                    }
                }
            },
        })
        .state('eds-detail', {
            url: '/app/eds-detail/:db/:an',
            data: { pageSubTitle: 'Article Details View' },
            controller: 'EbscoBibDetailCtrl',
            templateUrl: '/app/static/partials/bib-detail-ebsco.html',
        })
        .state('search-results.indexdata', {
            url: '/app/indexdata-search?query&pagenumber&resultsperpage',
            data: { pageSubTitle: 'Index Data Search Results' },
            reloadOnSearch: true,
            views: {
                facets : {
                    templateUrl: '/app/static/partials/facets-indexdata.html',
                    controller: 'IndexDataFacetCtrl'
                },
                resultlist: {
                    templateUrl: '/app/static/partials/resultlist-indexdata.html',
                    controller: 'IndexDataSearchResultsCtrl'
                },
                searchsummary: {
                    templateUrl: '/app/static/partials/searchsummary-indexdata.html',
                    controller: 'IndexDataSearchSummaryCtrl'
                },
                querysummary: {
                    component: 'searchQuerySummary',
                    bindings: {
                        search : 'searchQuery',
                    }
                }
            },
        })
        .state('indexdata-detail', {
            url: '/app/indexdata-detail/:id',
            data: { pageSubTitle: 'Article Details View' },
            controller: 'IndexDataBibDetailCtrl',
            templateUrl: '/app/static/partials/bib-detail-indexdata.html',
        })
        .state('work', {
            url: '/app/work/:bibid?place_hold',
            templateUrl: '/app/static/partials/bib-detail.html',
            controller: 'BibDetailCtrl',
            data: {
                pageSubTitle: 'Details for ',
            },
            resolve: {
                bib: ["$stateParams", "bibService", function($stateParams, bibService) {
                    return bibService.get($stateParams.bibid);
                }],
                holdings: ["$stateParams", "bibService", function($stateParams, bibService) {
                    return bibService.holdings($stateParams.bibid);
                }]
            },
        })
        .state('course-reserves', {
            url: '/app/course-reserves?course_id',
            templateUrl: '/app/static/partials/coursereserves.html',
            controller: 'CourseReservesCtrl',
            data: {
                pageSubTitle: 'Course Reserves',
            },
        })
        .state('authorities', {
            url: '/app/authorities', 
            templateUrl: '/app/static/partials/authorities.html',
            controller: 'AuthoritiesCtrl',
            data: {
                pageSubTitle: 'Authorities Search',
            },
        })
        .state('cart', {
            url: '/app/cart', 
            templateUrl: '/app/static/partials/cart-modal.html',  // demodalize.
            controller: 'CartCtrl',
            data: {
                pageSubTitle: 'My Cart',
            },
        })
        .state('tag-cloud', {
            url: '/app/tag-cloud',
            templateUrl: '/app/static/partials/tagcloud.html',
            controller: ["$scope", "kohaTagsSvc", function ($scope, kohaTagsSvc) {
                kohaTagsSvc.cloud().then(function (tags) {
                    $scope.tags = tags;
                });
            }],
            data: {
                pageSubTitle: 'Tag Cloud',
            },
        })
        .state('message-detail', {
            url: '/app/message/:msgid?view',
            templateUrl: '/app/static/partials/message-detail.html',
            controller: 'MessageDetailCtrl',
            data: {
                pageSubTitle: 'Message Details',
            },
            resolve: {
                message: ["messageService", "$stateParams", function(messageService, $stateParams) {
                    return messageService.get($stateParams.msgid);
                }],
                view: ["$stateParams", function($stateParams) {
                    return $stateParams.view;
                }],
            }
        })
        .state('job-detail', {
            url: '/app/job/:id?view',
            templateUrl: '/app/static/partials/job-detail.html',
            controller: 'JobDetailCtrl',
            data: {
                pageSubTitle: 'Job Details',
            },
            resolve: {
                job: ["jobService", "$stateParams", function(jobService, $stateParams) {
                    // TODO: Just get the descriptive_fields of the run and fetch the return_value of the data later. 
                    // If the run happens to be a report, it can be huge, and we'll want to paginate.
                    return jobService.get($stateParams.id);
                }],
                view: ["$stateParams", function($stateParams) {
                    return $stateParams.view;
                }],
            }
        })
        .state('self-register', {
            url: '/app/self-register',
            templateUrl: '/app/static/partials/self-register.html',
            controller: 'SelfRegisterCtrl',
        })
        .state('batch-manager', {
            url: '/app/batchManager',
            templateUrl: '/app/static/partials/productUpload.html',
            controller: 'AddProductCtrl',
            pageSubTitle: 'Batch Manager',
            resolve: {
                resolvedUser: ["userService", function(userService) {
                    return userService.whenAuthenticatedUserDetails({redirectOnFail: '/'});
                }],
            },
        })
        .state('checkout-kiosk', {
            url: '/app/checkout-kiosk',
            templateUrl: '/app/static/partials/checkout-kiosk.html',
            controller: 'CheckoutKioskCtrl',
            data: {
                pageSubTitle: 'Self Checkout',
                restrict: 'KioskSwitch'
            },
        })
        .state('lostpass', {
            url: '/app/lostpass?token',
            templateUrl: '/app/static/partials/lostpass.html',
            controller: 'LostPassCtrl',
        })
        .state('eresource', {
            url: '/app/eresource',
            templateUrl: '/app/static/partials/eresource/index.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceCtrl',
        })
        .state('eresource.search', {
            url: '/search?query&meta',
            templateUrl: '/app/static/partials/eresource/search.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceSearchCtrl',
        })
        .state('eresource.search-results', {
            url: '/search-results?query&meta',
            templateUrl: '/app/static/partials/eresource/search-results.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceSearchResultsCtrl',
        })
        .state('eresource.journals', {
            url: '/journals?query&meta',
            templateUrl: '/app/static/partials/eresource/journals.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceJournalsCtrl',
        })
        .state('eresource.collections', {
            url: '/collections?query&meta',
            templateUrl: '/app/static/partials/eresource/collections.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceCollectionsCtrl',
        })
        .state('eresource.collection-entries', {
            url: '/collection-entries?collection&provider&count',
            templateUrl: '/app/static/partials/eresource/search-results-ce.html',
            pageSubTitle: 'Electronic Resources',
            controller: 'EResourceCollectionEntriesCtrl',
        })
        
        // User login
        // Note that child state URLs are RELATIVE by default
        // (this was not obvious to me!)

        .state('me', {
            url: '/app/me',
            templateUrl: '/app/static/partials/user/index.html',
            controller: 'UserBaseCtrl as dashboard',
            data: {
                pageSubTitle: 'My Account',
                requiresAuth: true,
            },
            resolve: {

                resolvedUser: ["userService", "configService", function(userService, configService) {
                    if(!userService.loggedin && configService.external_auth.saml && !configService.external_auth.saml.iframe){
                        window.location.assign('/api/saml/login');
                        return {};
                    }
                    return userService.whenAuthenticatedUserDetails({redirectOnFail: '/'});
                }],
            },
        })
        .state('me.test', {
            url: '/test',
            template: '<b>Child test with {{user}}</b>',
        })
        .state('me.dashboard', {
            url: '/dashboard',
            templateUrl: '/app/static/partials/user/dashboard.html',
            data: {
                pageSubTitle: 'My Account',
            },
        })
        .state('me.issues', {
            url: '/checkouts',
            templateUrl: '/app/static/partials/user/issues.html',
            controller: 'UserIssuesCtrl',
            data: {
                pageSubTitle: 'My Checkouts',
            },
        })
        .state('me.issue-history', {
            url: '/checkout-history',
            templateUrl: '/app/static/partials/user/issue-history.html',
            controller: 'UserHistoryCtrl',
            data: {
                pageSubTitle: 'My Borrowing History',
            },
        })
        .state('me.holds', {
            url: '/holds',
            templateUrl: '/app/static/partials/user/holds.html',
            controller: 'UserHoldsCtrl',
            data: {
                pageSubTitle: 'My Holds',
            },
        })
        .state('me.hold-history', {
            url: '/hold-history',
            templateUrl: '/app/static/partials/user/hold-history.html',
            controller: 'UserHoldHistoryCtrl',
            data: {
                pageSubTitle: 'My Holds History',
            },
        })
        .state('me.fines', {
            url: '/fines',
            templateUrl: '/app/static/partials/user/fines.html',
            controller: 'UserFinesCtrl',
            data: {
                pageSubTitle: 'My Fines',
            },
        })
        .state('me.callslips', {
            url: '/callslips',
            templateUrl: '/app/static/partials/user/callslips.html',
            controller: 'UserCallslipsCtrl',
            data: {
                pageSubTitle: 'My Requests'
            },
        })
        .state('me.details', {
            url: '/details',
            templateUrl: '/app/static/partials/user/details.html',
            controller: 'UserUpdateCtrl',
            data: {
                pageSubTitle: 'My Personal Details',
            },
        })
        .state('me.lists', {
            url: '/lists',
            templateUrl: '/app/static/partials/user/lists.html',
            controller: 'UserListsCtrl',
            data: {
                pageSubTitle: 'My Shelves',
            },
            resolve: {
                resolvedLists: ["kohaListsSvc", "resolvedUser", function(kohaListsSvc, resolvedUser) {
                    return kohaListsSvc.sync();
                }],
            },
        })
        .state('me.tags', {
            url: '/tags',
            templateUrl: '/app/static/partials/user/tags.html',
            controller: 'UserTagsCtrl',
            data: {
                pageSubTitle: 'My Tags',
            },
        })
        .state('me.proxy-rel', {
            url: '/proxy-relations',
            templateUrl: '/app/static/partials/user/proxy-rel.html',
            // controller: 'UserProxyRelCtrl',
            data: {
                pageSubTitle: 'My Borrowing Relations',
            },
        })
        .state('me.patron-group', {
            url: '/patron-group',
            templateUrl: '/app/static/partials/user/patron-group.html',
            controller: 'UserPatronGroupCtrl',
            data: {
                pageSubTitle: 'My Patron Group',
            },
        })
        .state('me.suggestions', {
            url: '/suggestions',
            templateUrl: '/app/static/partials/user/suggestions.html',
            controller: 'UserSuggestionsCtrl',
            data: {
                pageSubTitle: 'My Purchase Suggestions',
            },
            resolve: {
                resolvedSuggestions: ["kohaSuggestSvc", "resolvedUser", function(kohaSuggestSvc, resolvedUser) {
                    return kohaSuggestSvc.mine();
                }],
            },
        })
        .state('me.illrequests', {
            url: '/illrequests',
            templateUrl: '/app/static/partials/user/illrequests.html',
            controller: 'UserILLRequestsCtrl',
            data: {
                pageSubTitle: 'My Interlibrary Loan Requests',
            },
            resolve: {
                resolvedIllRequests: ["kohaILLRequestSvc", "resolvedUser", function(kohaILLRequestSvc, resolvedUser) {
                    return kohaILLRequestSvc.mine();
                }],
            },
        })
        .state('me.messages', {
            url: '/messages',
            templateUrl: '/app/static/partials/user/messages.html',
            controller: 'UserMessagesCtrl',
            data: {
                pageSubTitle: 'My Messages',
            },
            resolve: {
                resolvedMessages: ["messageService", "resolvedUser", function(messageService, resolvedUser) {
                    return messageService.getList();
                }]
            },
        })
        .state('me.message-prefs', {
            url: '/message-prefs',
            templateUrl: '/app/static/partials/user/message-prefs.html',
            controller: 'UserMessagePrefsCtrl',
            data: {
                pageSubTitle: 'My Messaging Preferences',
            },
            resolve: {
                resolvedMsgPrefs: ["messageService", "resolvedUser", function(messageService, resolvedUser) {
                    return messageService.getPrefs();
                }]
            },
        })
        .state('me.prefs', {
            url: '/prefs',
            templateUrl: '/app/static/partials/user/prefs.html',
            controller: 'UserPrefsCtrl',
            data: {
                pageSubTitle: 'My User Preferences',
            },
        })

        .state('me.jobs', {
            url: '/jobs',
            templateUrl: '/app/static/partials/user/jobs.html',
            controller: 'UserJobsCtrl',
            data: {
                pageSubTitle: 'My Jobs',
            },
            resolve: {
                resolvedJobs: ["jobService", "resolvedUser", function(jobService, resolvedUser) {
                    return jobService.getList();
                }]
            },
        })

        .state('me.acqlists', {
            url: '/acqlists', 
            templateUrl: '/app/static/partials/user/acqlists/index.html',
            controller: 'UserAcqListsCtrl',
            data: {
                pageSubTitle: 'My Acquisition Lists',
            },
            resolve: {
                resolvedAcqLists: ["resolvedUser", "acqListsModel", function(resolvedUser, acqListsModel) {
                    return acqListsModel.forUser({user: resolvedUser.id}).$promise;
                }]
            },
        })

        .state('me.acqlists_switch', {
            url: '/acqlists_switch',
            template: '',
            controller: 'UserAcqListsButtonCtrl',
            resolve: {
                resolvedAcqLists: ["resolvedUser", "acqListsModel", function(resolvedUser, acqListsModel) {
                    return acqListsModel.forUser({user: resolvedUser.id}).$promise;
                }]
            },
        })

        .state('me.acqlists-details', {
            url: '/acqlists/:id', 
            templateUrl: '/app/static/partials/user/acqlists/details.html',
            controller: 'UserAcqListDetailsCtrl',
            data: {
                pageSubTitle: 'Acquisition List Details',
            },
            resolve: {
                resolvedAcqList: ["resolvedUser", "acqListsModel", "$stateParams", function(resolvedUser, acqListsModel, $stateParams) {
                    return acqListsModel.details({id:$stateParams.id}).$promise;
                }],
                resolvedAcqListEntries: ["resolvedUser", "acqListsModel", "$stateParams", "alertService", "$q", function(resolvedUser, acqListsModel, $stateParams, alertService, $q) {
                    return acqListsModel.entries({id:$stateParams.id}).$promise.then(function(data) {
                        return data;
                    }, function(err) {
                        alertService.add({msg: err, type: "error"});
                        return $q.reject(err);
                    });
                }]
            },
        })
        .state('me.acqsubs', {
            url: '/acqsubs', 
            templateUrl: '/app/static/partials/user/acqlists/subscriptions.html',
            controller: 'UserAcqSubsCtrl',
            data: {
                pageSubTitle: 'My Subscriptions',
            },
            resolve: {
                resolvedOrders: ["resolvedUser", "acqListsModel", function(resolvedUser, acqListsModel) {
                    return acqListsModel.branchOrders({branch_code: resolvedUser.branchcode}).$promise;
                }]
            },
        })
        .state('me.social', {
            url: '/social', 
            templateUrl: '/app/static/partials/user/social.html',
            controller: 'UserSocialCtrl',
            data: {
                pageSubTitle: 'Social Login',
            },
            resolve: {
                socialMediaAccounts: ["kwApi", "userService", function(kwApi, userService) {
                    return userService.whenGetUserId().then(function(id) {
                        console.log("Resolving");
                        return kwApi.Patron.getSocialMediaAccounts({id: id}).$promise;
                    });
                }],
            },
        })
        .state('me.bind', {
            url: '/bind/:type?code',
            templateUrl: '/app/static/partials/user/bind.html',
            controller: 'UserSocialBindCtrl',
            data: {
                pageSubTitle: 'Social Login',
            },
        })
        .state('login-social', {
            url: '/app/login-social/:type?code',
            templateUrl: '/app/static/partials/login-social.html',
            controller: 'UserSocialLoginCtrl',
            data: {
                pageSubTitle: 'Social Login',
            },
        })

        .state('403', {
            url: '/app/error/403',
            templateUrl: '/app/static/partials/errors/403.html',
            controller: 'Error403Ctrl',
            data: {
                pageSubTitle: '403 Not Authorized',
            },
        })
        .state('404', {
            url: '/app/error/404',
            templateUrl: '/app/static/partials/errors/404.html',
            controller: 'Error404Ctrl',
            data: {
                pageSubTitle: '404 Not Found',
            },
        })
        ;

        $urlRouterProvider.otherwise('/app/error/404');

    $locationProvider.html5Mode(true);

//    $httpProvider.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded; charset=UTF-8";

    var is_static_file = /^\/app\/static\/\S+\.(html|js|json:?)$/;

    $httpProvider.interceptors.push(["$q", "VERSION", "$rootScope", function($q, VERSION, $rootScope){
      return {
        request: function(config){
          if(config.method == 'GET' && is_static_file.test(config.url) && !KOHA.config.jsDebug){
            // If it's already in cache, then assume it was put there on purpose.
            if(!(config.cache && config.cache.get(config.url))){
              config.url = config.url + "?v=" + VERSION;
            }
          }
          return config || $q.when(config);
        },
        response: function(response) {
          if (!response.config.cache) {
            var fh = response.headers('X-Fixtures-Hash');
            if (fh && (fh !== KOHA._fixtures_hash)) {
              //console.log("Old fixtures hash: " + KOHA._fixtures_hash);
              //console.log("New fixtures hash (response): " + fh);
              //console.dir(response.config);
              if (KOHA._fixtures_hash && (KOHA._fixtures_hash !== '{}')) {
                $rootScope.$emit('fixturesExpired');
              }
              KOHA._fixtures_hash = fh;
            }
          }
          return response;
        }
      };
    }]);


    /*kohaHttpSessInterceptorProvider.authenticated(function(injector){

//        console.log(injector.get('userService').whenAuthenticatedUser);
        return injector.get('userService').whenAuthenticatedUser();

    }).reauthenticated(function(injector){
      return injector.get('userService').whenAuthenticatedUser();
    }).autoReauthenticate(true).authRequired(function(config){

// console.log(config);

        //  Note: We can't add properties to the $http config object when we use $resource, but we can when using $http directly.

        if(config.authRequired) return true;

        var req = [ /app\/staff\//, ];
        for (var i = 0; i < req.length; i++) {
            if(req[i].test(config.url)) return true;
        }
        return false;
    });*/
    $httpProvider.interceptors.push('kohaHttpSessInterceptor');

    $ocLazyLoadProvider.config({
        // debug: true
    });


    $provide.decorator('$uibModal', ["$delegate", "configService", function $uibModalDecorator($delegate, configService){
        var origOpen = $delegate.open;
        $delegate.open = function(options) {
            var rv = origOpen(options);
            rv.rendered.then(function(){
                configService.runUserJs();
            });
            return rv;
        };
        return $delegate;
    }]);



    // Defaults for ui-bootstrap components:
    uibPaginationConfig.itemsPerPage = KOHA.config.OPACnumSearchResults;
    for( var k in KOHA.config.pager ){
        uibPaginationConfig[k] = KOHA.config.pager[k];
    }
    uibDatepickerPopupConfig.showWeeks = false;

    // UPGRADE -- animations conflict with bootstrap 5 css .  switch to ngbModal
    $uibModalProvider.options.animation = false;

    // FIXME - angular sucks at digesting trees
    $rootScopeProvider.digestTtl(15);

}]).run(
  ["$rootScope", "$state", "$location", "$timeout", "$http", "$window", "userService", "$trace", "configService", "kohaListsSvc", "alertService", "cartService", "staffRedirectService", "$transitions", "marcBibSpec", "loading", "$q", function($rootScope, $state, $location, $timeout, $http, $window, userService, $trace,
        configService, kohaListsSvc, alertService, cartService, staffRedirectService,
        $transitions, marcBibSpec, loading, $q){

    // ui-router transition trace.
    // TODO: migrate use of $stateChange* to transition hooks, remove stateEvents.js.
    // $trace.enable('TRANSITION');

        $transitions.onStart({}, function(trans){

            var jj = [
                marcBibSpec.loaded
            ];
            return $q.all( jj );

        });

       // Angular has loaded, we can safely reset logging
       KOHA.logIntercept(false);

       try {
            dayjs.extend(dayjs_plugin_utc); // eslint-disable-line no-undef
       } catch (e) {
            console.warn(e);
       }

// $rootScope.$on("$stateChangeError", console.log.bind(console));
    $rootScope.$on("$stateChangeError",
      function (event, toState, toParams, fromState, fromParams, error) {
        console.warn(error);
        if (typeof(error) === 'object') {
            if(!error.data) return error;
            if (error.data.substr(0,3) == '404') {
                event.preventDefault();
                $state.go('404');
            }
            else if (error.data.substr(0,3) == '403') {
                event.preventDefault();
                $state.go('403');
            }
        }
        else if ( error.substr(0,3) == '404') {
            event.preventDefault();
            $state.go('404');
        }
        else if ( error.substr(0,3) == '403') {
            event.preventDefault();
            $state.go('403');
        }
      });

    var alertMsg = $location.search().alert_error;
    if (alertMsg) {
        alertService.add({msg: alertMsg, type: "error"});
        $location.search('alert_error',null);
    }
    
    var ticket = $location.search().proxy_ticket;
    if (ticket) {
        $http.defaults.headers.common['X-Proxy-Ticket'] = ticket;
        $location.search('proxy_ticket', null);
    }
    else if (ticket = $location.search().auth_ticket) { // eslint-disable-line no-cond-assign
        $http.defaults.headers.common['X-Auth-Ticket'] = ticket;
        $location.search('auth_ticket', null);
    }

    $rootScope.$on('loggedout', function(){
        if(configService.authRequired) $rootScope.$broadcast('loginRequired');
    });

    $rootScope.$on('loggedin', function(){
      kohaListsSvc.sync();
    });
    $rootScope.$on('clearUserData', function(){
        // clear all user data from services.
        kohaListsSvc.clear_private();
        cartService.removeAll();
        if ($location.path().match(/\/me\//)) $location.url('/');

    });
    $rootScope.$on('fixturesExpired', function() {
        configService.reloadFixtures();
    });

    $rootScope.pageTitle = configService.pageTitle;
    $rootScope.pageSubTitle = 'Home';  // Updated on routeChangeSuccess.

    $("body").on('click', "a", function(el){
        if(/^(http:\/\/|https:\/\/|\/\/)/.test($(this).attr('href'))){
            if (!$(this).attr('target')) {
                $(this).attr('target','_blank');
            }
        }
    });

    // Run userJS on setup:
    if(configService.userJS.load && typeof configService.userJS.load === 'function'){
        if (window._mastheadReady){
            configService.userJS.load();
        } else {
            var dereg = $rootScope.$on('MastheadReady', function(){
                configService.userJS.load();
                dereg();
            });
            // window.addEventListener( 'MastheadReady', configService.userJS.load);
        }
    }

    if(configService.favicon){
      $(document).ready(function(){
        $('<link rel="icon" href="'+configService.favicon +'">').appendTo('head');
      });
    }

    $rootScope.$on("$locationChangeStart", function(e, next, cur){
      // console.log("Location change start");
      // console.log(next);
      // console.log(cur);

      // When the state change is suppressed, $location will try to redirect back (so helpful)
      if (staffRedirectService.suppressLocationChange(cur,next)) {
          e.preventDefault();
      }

      else if(next.$$route && next.$$route.staffRoute){
        if(userService.loggedin && !userService.is_staff){
          e.preventDefault();
        }
      }
    });

    var sessExpDebounce;
    $rootScope.$on('sessionExpired', function(){
        if(!sessExpDebounce){
            sessExpDebounce = $timeout(function(){ sessExpDebounce = null; },100);
            alertService.add({msg: "You have been logged out." });
        }
        userService.whenGetUserId(function(id){
            if(id > 0){
                userService.clear();
                if($location.path().match(/\/me\//)) $location.url('/');
            }
        });
    });

    $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
        // console.log("State Change Start");
        // console.dir(toState);
        // console.dir(toParams);
        // console.dir(fromState);
        // console.dir(fromParams);
        if (toState.data && toState.data.restrict === 'KioskSwitch') {
            if (!configService.CheckoutKiosk) {
                e.preventDefault();
                $state.go('home');
            }
            else {
                $http.head('/api/kiosk/-1').catch( function () { e.preventDefault(); $state.go('home'); });
            }
        }
        if( ! userService.hasValidSession() ){
            userService.clear();
        }
        if (staffRedirectService.suppressStateChange(fromState, fromParams, toState, toParams)) {
            e.preventDefault();
        }
        else if (configService.showSpinnerOnStateChange)
            loading.add('state');

    });

    $rootScope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState, fromParams) {
        // FIXME - this is not an ideal solution
        // see also https://github.com/angular-ui/ui-router/issues/92
        // see also http://plnkr.co/edit/DJH6mQUCbTFfSbdCBYUo?p=preview 
        $state.previous = fromState;
        $state.previousParams = fromParams;
        $rootScope.currentState = toState;

// console.log('STATE CHANGE: ');
// console.log($state.previous);
// console.log(toState);
// console.dir(angular.copy(toParams));

        // Run userJS on route change:
        if (configService.userJS.page && typeof configService.userJS.page === 'function'){
            $timeout(configService.userJS.page,1); // ensure dom is ready.
        }
        let pageSubTitle = (toState.data||{}).pageSubTitle;
        document.title = $rootScope.pageTitle + ( pageSubTitle ? ' | ' + pageSubTitle  : "" );

        $rootScope.staffRoute = toState.name.match(/staff/) ? true : false;

        if($window.ga) $window.ga('send', 'pageview', { page: $location.path() });  //google-analytics

        // FIXME - this smells
        if (toParams.query == '*:*') {
            if (toParams.sort == 'popularity desc') {
                $rootScope.viewId = 'most-popular';
            }
            else if (toParams.fq && !angular.isArray(toParams.fq) && toParams.fq.match(/catdate:\[NOW-\d+DAYS TO \*\]/)) {
                $rootScope.viewId = 'new-titles';
            }
        }
        if (configService.showSpinnerOnStateChange)
            loading.resolve('state');

    });


}]);

// }, (e) => console.error(e) ); // KOHA.status.configured.then();

})();
